<template>
  <v-container class="pa-md-5" fluid>
    <v-row>
      <v-col cols="12">
        <h2 class="secondary--text">Inventario</h2>
        <v-divider class="mb-8 mt-2"></v-divider>
        <ProductList />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductList from "../components/Product/ProductList.vue";

export default {
  name: "Inventory",
  components: {
    ProductList,
  },
};
</script>
